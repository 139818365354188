import { defineStore } from 'pinia';
import { handleError } from '@/devicehub/utils/error';
import { useStudyDataExportApi } from './studyDataExportApi';
import { useStudyApi } from '@/devicehub/apps/dhStudy/stores/studyApi';
import { DeviceDetail } from '@/generated/api/study';

export interface DataExportState {
  loading: boolean;
  jobId: string | null;
  exportInProgress: boolean;
  canDownload: boolean;
  page: number;
}

export const useDataExportStore = defineStore({
  id: 'dataExport',
  state: (): DataExportState => ({
    loading: false,
    jobId: null,
    exportInProgress: false,
    canDownload: false,
    page: 0,
  }),
  getters: {
    downloadLink(): string {
      const studyDataExportApi = useStudyDataExportApi();
      return `${studyDataExportApi.basePath}/download?job_id=${this.jobId}`;
    },
  },
  actions: {
    async startDataExport(
      startTime: Date,
      endTime: Date,
      deviceUUID: string,
      streamId: string,
    ): Promise<void> {
      const studyDataExportApi = useStudyDataExportApi();
      this.loading = true;
      try {
        const response =
          await studyDataExportApi.appApi.triggerDataExportTriggerDataExportGet(
            startTime.toISOString(),
            endTime.toISOString(),
            deviceUUID,
            streamId,
          );
        this.jobId = response.data;
        this.page = 1;
        this.canDownload = false;
        this.exportInProgress = true;
        this.scheduleUpdateStatus();
      } catch (error) {
        this.jobId = null;
        this.canDownload = false;
        this.exportInProgress = false;
        handleError(error);
      }
      this.loading = false;
    },

    scheduleUpdateStatus(): void {
      if (this.jobId && this.exportInProgress) {
        setTimeout(() => {
          this.updateStatus();
        }, 2000);
      }
    },

    async updateStatus(): Promise<void> {
      const studyDataExportApi = useStudyDataExportApi();
      if (this.jobId) {
        try {
          const response =
            await studyDataExportApi.appApi.exportStatusExportStatusGet(
              this.jobId,
            );
          if (response.data.completed) {
            this.canDownload = true;
            this.exportInProgress = false;
          }
          this.page = response.data.last_page;
        } catch (error) {
          handleError(error);
        }
        this.scheduleUpdateStatus();
      }
    },

    async download(
      startTime: Date,
      endTime: Date,
      deviceUUID: string,
      streamId: string,
    ): Promise<void> {
      const studyDataExportApi = useStudyDataExportApi();
      const studyApi = useStudyApi();
      this.loading = true;
      if (this.jobId) {
        try {
          const responses = await Promise.all([
            studyApi.deviceApi.getDeviceDetailListDevicesDetailGet(),
            studyDataExportApi.appApi.downloadDownloadGet(this.jobId),
          ]);

          const [deviceResponse, downloadResponse] = responses;

          const hash = this.jobId.split('-')[0];
          const fileName = this.getDownloadFileName(
            deviceResponse.data,
            deviceResponse.status !== 200,
            deviceUUID,
            streamId,
            hash,
          );

          if (downloadResponse?.status === 200) {
            this.downloadBlobAsFile(
              downloadResponse.data + '',
              'text/csv',
              fileName,
              deviceUUID,
              streamId,
              startTime,
              endTime,
            );
          }
        } catch (error) {
          handleError(error);
        }
      }
      this.loading = false;
    },

    getDownloadFileName(
      devices: DeviceDetail[],
      invalidStatus: boolean,
      deviceUUID: string,
      streamId: string,
      hash: string,
    ): string {
      if (!devices?.length || invalidStatus) return `data_export_${hash}.csv`;
      const findDevice = devices.find(
        deviceDetail => deviceDetail.device.id + '' === deviceUUID + '',
      );
      if (!findDevice) return `data_export_${hash}.csv`;
      return `${
        findDevice.device.name
      }-${streamId}_${new Date().toISOString()}.csv`;
    },

    downloadBlobAsFile(
      blobData: string,
      mimeType: string,
      fileName: string,
      deviceUUID: string,
      streamId: string,
      startTime: Date,
      endTime: Date,
    ) {
      const metadata = [
        ['Device UUID', deviceUUID],
        ['Stream ID', streamId],
        ['Start Time', startTime.toLocaleString()],
        ['End Time', endTime.toLocaleString()],
        ['Download Time', new Date().toLocaleString()],
      ];

      const metadataString =
        metadata.map(row => row.join(';')).join('\n') + '\n';
      const csvContent = metadataString + blobData;
      const blob = new Blob([csvContent], { type: mimeType });

      const url: string = window.URL.createObjectURL(blob);

      const fileLink = document.createElement('a');
      fileLink.style.display = 'none';
      fileLink.href = url;
      fileLink.download = fileName;

      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(url);
    },
  },
});
