










































































// import { useI18n } from '@/devicehub/utils/i18n';
import { computed, defineComponent, reactive } from '@vue/composition-api';
import { useDataExportStore } from '@/devicehub/apps/dhStudy/stores/dataExportStore';

interface FormValues {
  startTime: Date | null;
  endTime: Date | null;
  deviceUuid: string | null;
  streamId: string | null;
}

export default defineComponent({
  components: {},
  setup() {
    const dataExportStore = useDataExportStore();
    const formValues = reactive<FormValues>({
      startTime: null,
      endTime: null,
      deviceUuid: null,
      streamId: null,
    });

    const canStartExport = computed<boolean>(() => {
      return (
        !!formValues.startTime &&
        !!formValues.endTime &&
        !!formValues.deviceUuid &&
        !!formValues.streamId &&
        !dataExportStore.exportInProgress
      );
    });

    const onStartExport = async () => {
      if (
        !!formValues.startTime &&
        !!formValues.endTime &&
        !!formValues.deviceUuid &&
        !!formValues.streamId
      ) {
        await dataExportStore.startDataExport(
          formValues.startTime,
          formValues.endTime,
          formValues.deviceUuid,
          formValues.streamId,
        );
      }
    };

    const onDownload = async () => {
      if (
        !!formValues.startTime &&
        !!formValues.endTime &&
        !!formValues.deviceUuid &&
        !!formValues.streamId
      ) {
        await dataExportStore.download(
          formValues.startTime,
          formValues.endTime,
          formValues.deviceUuid,
          formValues.streamId,
        );
      }
    };

    const onCancel = () => {
      formValues.startTime = null;
      formValues.endTime = null;
      formValues.deviceUuid = null;
      formValues.streamId = null;
      dataExportStore.$reset();
    };

    return {
      dataExportStore,
      formValues,
      canStartExport,
      onStartExport,
      onDownload,
      onCancel,
    };
  },
});
